import { CancelTokenSource } from 'axios';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCancelTokenSource } from '../../api/client';
import { getStoreConfigs, getStoreDetailByNumber } from '../../api/store';
import { StoreConfigsResponse, StoreDetail } from '../../types/types';
import { getSelectedStore } from '../../utils/utils';

export interface StoreState {
  store?: StoreDetail;
  loading: boolean;
  hasApiError: boolean;
  isAddressDoctorEnabled: boolean;
  isRetentionEnabled: boolean;
  referenceContactInterval: number;
  contactWeekLimit: number;
}

const NO_LIMIT = 9999;
const NO_LIMIT_STRING = '-1';
const NO_INTERVAL_LIMIT = 0;

export interface StoreDispatchState {
  fetchStoreDetailsByNumber: (
    storeNumber: string,
    cancelToken: CancelTokenSource
  ) => Promise<void | StoreDetail[]>;
}

export const checkIfAddressDoctorEnabled = (
  response: StoreConfigsResponse[]
): boolean => {
  const addressDoctor = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'AddressDoctorEnabledIndicator' &&
      config.paramCategoryName === 'AddressDoctorEnabled' &&
      config.paramKeyName === 'AddressDoctorEnabled'
    );
  });
  if (addressDoctor && addressDoctor.paramValue === '1') {
    return true;
  }
  return false;
};

export const checkIfRetentionEnabled = (
  response: StoreConfigsResponse[]
): boolean => {
  const retentionResponse = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'PaymentAccess' &&
      config.paramCategoryName === 'SubSystemAccess' &&
      config.paramKeyName === 'CustomerRetention'
    );
  });
  if (retentionResponse && retentionResponse.paramValue === '1') {
    return true;
  }
  return false;
};
export const getContactWeekLimit = (
  response: StoreConfigsResponse[]
): number => {
  const configResp = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'CommunicationLimit' &&
      config.paramCategoryName === 'AccountManagement' &&
      config.paramKeyName === 'CustomerContactLimitPastWeek'
    );
  });
  if (configResp && configResp.paramValue !== NO_LIMIT_STRING) {
    return Number(configResp.paramValue);
  }
  return NO_LIMIT;
};

export const getReferenceContactInterval = (
  response: StoreConfigsResponse[]
): number => {
  const configResp = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'CommunicationLimit' &&
      config.paramCategoryName === 'AccountManagement' &&
      config.paramKeyName === 'ReferenceContactInterval'
    );
  });
  if (configResp && configResp.paramValue !== NO_LIMIT_STRING) {
    return Number(configResp.paramValue);
  }
  return NO_INTERVAL_LIMIT;
};

export const StoreStateContext = createContext<StoreState>({} as StoreState);
export const StoreDispatchContext = createContext<StoreDispatchState>(
  {} as StoreDispatchState
);

export const StoreProvider = (props: { children: ReactNode }) => {
  const [store, setStore] = useState<StoreDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasApiError, setHasApiError] = useState(false);
  const [isAddressDoctorEnabled, setIsAddressDoctorEnabled] = useState(false);
  const [isRetentionEnabled, setIsRetentionEnabled] = useState(false);
  const [contactWeekLimit, setContactWeekLimit] = useState(NO_LIMIT);
  const [referenceContactInterval, setReferenceContactInterval] =
    useState(NO_LIMIT);

  const fetchStoreConfig = (
    selectedStore: string,
    cancelToken: CancelTokenSource
  ) => {
    const paramKeyName = [
      'AddressDoctorEnabled',
      'CustomerRetention',
      'CustomerContactLimitPastWeek',
      'ReferenceContactInterval',
    ];
    getStoreConfigs(
      {
        storeNumbers: [selectedStore],
        paramKeyNames: paramKeyName,
      },
      cancelToken.token
    )
      .then((response) => {
        setIsRetentionEnabled(checkIfRetentionEnabled(response));
        setIsAddressDoctorEnabled(checkIfAddressDoctorEnabled(response));
        setContactWeekLimit(getContactWeekLimit(response));
        setReferenceContactInterval(getReferenceContactInterval(response));
      })
      .catch(() => {
        //noop
      });
  };

  useEffect(() => {
    const cancelToken: CancelTokenSource = getCancelTokenSource();
    const selectedStore = getSelectedStore();
    fetchStoreDetailsByNumber(selectedStore, cancelToken);
    fetchStoreConfig(selectedStore, cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, []);

  const fetchStoreDetailsByNumber = async (
    storeNumber: string,
    cancelToken: CancelTokenSource
  ) => {
    setHasApiError(false);
    setLoading(true);
    getStoreDetailByNumber(storeNumber, cancelToken.token)
      .then(
        (response) =>
          response?.response?.length && setStore(response.response[0])
      )
      .catch(() => setHasApiError(true))
      .finally(() => setLoading(false));
  };

  return (
    <StoreStateContext.Provider
      value={{
        store,
        loading,
        hasApiError,
        isAddressDoctorEnabled,
        isRetentionEnabled,
        referenceContactInterval,
        contactWeekLimit,
      }}
    >
      <StoreDispatchContext.Provider
        value={{
          fetchStoreDetailsByNumber,
        }}
      >
        {props.children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  );
};

export const useStoreDetails = () => useContext(StoreStateContext);

export const useStoreActions = () => useContext(StoreDispatchContext);
