import {
  makeStyles,
  RACBadge,
  RACBreadcrumb,
  RACBreadcrumbLinkType,
  Typography,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { AppRoute } from '../../config/route-config';
import { DATE_FORMAT } from '../../constants/constants';
import { useCustomerDetails } from '../../context/CustomerDetails/CustomerDetailsProvider';
import { PastDueCustomerResponse } from '../../domain/PastDueList/PastDueCustomerList';
import { formatDateString, formatMoney } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
export interface CustomerProps {
  customer?: PastDueCustomerResponse;
}

const useStyles = makeStyles((theme: any) => ({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  breadcrumbContainer: {
    width: '100%',
    height: theme.typography.pxToRem(45),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  deInfoContainer: {
    right: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(40),
  },
  deInfo: {
    color: '#2179FE',
  },
  deStatusDetail: {
    paddingTop: theme.typography.pxToRem(8),
  },
  approvalExpiryInfo: {
    color: '#5b6d83',
  },
}));

export const initialLinks = [
  {
    href: `${AppRoute.Index}`,
    label: 'Dashboard',
  },
  {
    href: `${AppRoute.PastDueList}`,
    label: 'Past Due List',
  },
];

export const initialLinksM = [
  {
    href: `${AppRoute.Dashboard}`,
    label: 'Dashboard',
  },
  {
    href: `${AppRoute.PastDueList}`,
    label: 'Past Due List',
  },
];

const getLinks = () => {
  if (window.location.pathname.includes('menu')) {
    return initialLinksM;
  }
  return initialLinks;
};
const getCustomerPath = (customerId: any) => {
  if (window.location.pathname.includes('menu')) {
    // return `/menu/customer/update/${customerId}/customer`;
    return `/customer/update/${customerId}/customer`;
  }
  return '/am/customer/' + customerId;
};

export const CustomerBreadcrumb = () => {
  const ownClasses = useStyles();
  const [links, setLinks] =
    React.useState<RACBreadcrumbLinkType[]>(initialLinks);
  const {
    customerDetails,
    loading,
    hasApiError,
    customerApprovalDetailsResp,
    customerApprovalLabel,
    customerVerificationLabel,
  } = useCustomerDetails();
  const { firstName, lastName, customerId, verifiedDate } =
    customerDetails || {};
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!customerDetails) return;
    const links = getLinks();
    const label =
      firstName && lastName && customerId
        ? `${firstName || ''} ${lastName || ''} - ${customerId || ''}`
        : '';
    setLinks([
      ...links,
      {
        href: `${getCustomerPath(customerId)}`,
        label: label,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);
  const handleOnclick = () => {
    /* eslint-disable no-console */
    console.log('OnClick');
  };
  const customerDetailLoaded =
    loading !== undefined && !loading && !hasApiError;

  const approvalExpirationDate = formatDateString(
    customerApprovalDetailsResp?.customerApprovalDetails
      ?.approvalExpirationDate,
    DATE_FORMAT
  );
  const verificationExpirationDate = formatDateString(
    verifiedDate,
    DATE_FORMAT
  );
  const handleClick = (event: any, href: string) => {
    history.push(href);
  };
  return (
    <>
      <ApiStateWrapper
        loading={loading}
        hasApiError={hasApiError}
        successContent={<></>}
        classes={{
          loader: ownClasses.contentHeight,
          apiError: ownClasses.contentHeight,
        }}
      />
      {customerDetailLoaded && (
        <div className={classes.row}>
          <div className={clsx(classes.breadcrumbContainer)}>
            <RACBreadcrumb links={links} onClick={handleClick} />
            <div className={classes.deInfoContainer}>
              <div className={classes.deInfo}>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    Approval Amount :{' '}
                  </Typography>
                </div>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    {formatMoney(
                      customerApprovalDetailsResp?.customerApprovalDetails
                        ?.approvalAmount
                    )}
                  </Typography>
                </div>
              </div>
              <div className={classes.deInfo}>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    Weekly Approval Amount :{' '}
                  </Typography>
                </div>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    {formatMoney(
                      customerApprovalDetailsResp?.customerApprovalDetails
                        ?.weeklyApprovalAmount
                    )}
                  </Typography>
                </div>
              </div>
              <div className={classes.deStatusDetail}>
                <div className={classes.row}>
                  <RACBadge
                    TextValue={customerVerificationLabel.text}
                    backgroundColor={customerVerificationLabel.bgColor}
                    color="white"
                    OnclickNeed={false}
                    handleOnClick={handleOnclick}
                  />
                </div>
                {customerVerificationLabel.showExpiryDate && (
                  <div className={classes.row}>
                    <div className={classes.approvalExpiryInfo}>
                      <Typography display="inline" variant="body1">
                        Expires on{' '}
                      </Typography>
                      <Typography display="inline" variant="body1">
                        {verificationExpirationDate}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.deStatusDetail}>
                <div className={classes.row}>
                  <RACBadge
                    TextValue={customerApprovalLabel.text}
                    backgroundColor={customerApprovalLabel.bgColor}
                    color="white"
                    OnclickNeed={false}
                    handleOnClick={handleOnclick}
                  />
                </div>
                {customerApprovalLabel.showExpiryDate && (
                  <div className={classes.row}>
                    <div className={classes.approvalExpiryInfo}>
                      <Typography display="inline" variant="body1">
                        Expires on{' '}
                      </Typography>
                      <Typography display="inline" variant="body1">
                        {approvalExpirationDate}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
