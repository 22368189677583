/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import {
  makeStyles,
  RACCOLOR,
  RACTable,
  RACTableCell,
  RACTableRow,
  Typography,
  RACModalCard,
  CircularProgress,
  Grid,
  RACButton,
  Card,
  CardContent,
  RACCheckBox,
} from '@rentacenter/racstrap';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useStoreDetails } from '../../../context/Store/StoreProvider';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ApiStateWrapper,
  Loader,
} from '../../../common/ApiStateWrapper/ApiStateWrapper';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../..';
import { useHistory } from 'react-router-dom';
import {
  useAgreementInfo,
  useAgreementInfoActions,
} from '../../../context/AgreementInfo/AgreementInfoProvider';
import { formatDateString } from '../../../utils/utils';
import { AgreementInfoDaysLate } from '../../../types/types';
import { AppRoute } from '../../../config/route-config';
import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
import SecondFactor from './TwoFactorPopup';
import {
  getRetentionOffers,
  postAgreementActivity,
  logWorkedHistory,
} from '../../../api/Customer';
import { useUserStateContext } from '../../../context/user/user-contexts';
const useClasses = makeStyles((theme: any) => ({
  tableContentColor: {
    color: RACCOLOR.INDEPENDENCE,
  },
  tableRoot: {
    marginTop: theme.typography.pxToRem(16),
  },
  // aligns the content in the cell
  storeRowContentCell: {
    display: 'flex',
  },
  storeRowContent: {
    position: 'absolute',
  },
  notVisible: {
    visibility: 'hidden',
  },
  tableBody: {
    position: 'relative',
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: 'white',
  },
  contentHeight: {
    height: theme.typography.pxToRem(400),
  },
  greenColumn: {
    backgroundColor: '#81c783',
  },
  number: {
    color: RACCOLOR.BLUE_CRAYOLA,
    cursor: 'pointer',
  },
  hyperLink: {
    cursor: 'pointer',
    color: RACCOLOR.BLUE_CRAYOLA,
    textDecoration: 'underline',
    textDecorationColor: RACCOLOR.BLUE_CRAYOLA,
  },
  textCenter: {
    textAlign: 'center',
  },
  bold: {
    fontFamily: 'OpenSans-bold',
  },
  gridLabel: {
    color: '#6C86A5',
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(14),
  },
  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  title: {
    color: '#000',
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(10),
  },
  mb1: {
    marginBottom: theme.typography.pxToRem(4),
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  payScheduleHighlight: {
    backgroundColor: `#2179FE`,
  },
  payScheduleNotHighlight: {
    backgroundColor: `${RACCOLOR.WHITE}`,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  paySchedulePeriodHighlight: {
    color: `${RACCOLOR.WHITE}`,
    fontSize: '15px',
    textAlign: 'center',
  },
  paySchedulePeriodNotHighlight: {
    textAlign: 'center',
    fontSize: '15px',
    color: '#22a3ff',
  },
  cardTile: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
    width: '100%',
  },
  listitemstyles: {
    backgroundColor: '#eff4ff',
    borderRadius: theme.typography.pxToRem(8),
  },
  paymentGeneralInfoColor: {
    color: `${RACCOLOR.NORMAL_BLUE}`,
    fontFamily: 'OpenSans-semibold',
    letterSpacing: 'unset',
    fontSize: '14px',
  },
  paymentGeneralInfo: {
    padding: '10px 13px',
    float: 'left',
  },
  borderRightRadius0: {
    borderTopRightRadius: theme.typography.pxToRem(0),
    borderBottomRightRadius: theme.typography.pxToRem(0),
  },
  pt1: {
    paddingTop: theme.typography.pxToRem(4),
  },
  w100: {
    width: '100%',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  px0: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  px20: {
    padding: 10,
  },
  h65: {
    height: '65px',
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  paymentAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  alertwidget: {
    borderRadius: '15px !important',
    background: '#eff4ff',
  },
  mb1p: {
    marginBottom: '1%',
  },
  raccollg6: {
    flex: '0 0 auto',
    width: '15%',
  },
  titletxtstyle: {
    fontSize: theme.typography.pxToRem(18),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0rem',
  },
  pe4: {
    paddingRight: theme.typography.pxToRem(24),
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  cardTileStyle: {
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: '#22a3ff',
  },
  justifyLabel: {
    justifyContent: 'center',
  },
}));

export const EMPTY_DAYS_LATE_HISTORY = [
  { dayslate: 'On_Time', count: 0 },
  { dayslate: '1-6_Days_Late', count: 0 },
  { dayslate: '7-14_Days_Late', count: 0 },
  { dayslate: '15+_Days_Late', count: 0 },
];

export const populateDaysLateHistory = (
  daysLateHistory: AgreementInfoDaysLate[] | null | undefined
): AgreementInfoDaysLate[] => {
  return EMPTY_DAYS_LATE_HISTORY.map((emptyDaysLateObj) => {
    const daysLateObjFromAPI = daysLateHistory?.find(
      (obj) => obj.dayslate === emptyDaysLateObj.dayslate
    );

    return daysLateObjFromAPI || emptyDaysLateObj;
  });
};

export const AgreementInfo = () => {
  const history = useHistory();
  const { isRetentionEnabled } = useStoreDetails();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const { stores, total, numberOfLoadedAgreements, hasApiError, loading } =
    useAgreementInfo();
  const { customerDetails } = useCustomerDetails();
  const { customerId } = customerDetails || {};
  const { user } = useUserStateContext();

  const [openReinstateAlert, setOpenReinstateAlert] = useState<boolean>(false);
  const [choosenRetentionOptions, setChoosenRetentionOptions] = useState<any>();
  const [retentionOptions, setRetentionOptions] = useState<any>();
  const [choosenOfferReason, setChoosenOfferReason] = useState<string>();
  const [offerResponse, setOfferResponse] = useState<any>();
  const [choosenAgreementID, setChoosenAgreementID] = useState<any>();
  const [choosenOptionIndex, setChoosenIndex] = useState<any>();
  const [openRetention, setOpenRetention] = useState<boolean>(false);
  const [openSLA, setOpenSLA] = useState<boolean>(false);
  const [openOfferLoading, setOpenOfferLoading] = useState<boolean>(false);
  const [choosenAgreementNumber, setChoosenAgreementNumber] = useState<any>();
  const [currentRole, setCurrentRole] = useState<any>();
  const [availableRetentionOffers, setAvailableOffers] = useState<any>();
  const [openActivityLoading, setOpenActivityLoading] =
    useState<boolean>(false);

  const availableOffers = [
    'Product',
    'No Longer Needs',
    'EPO too high',
    'Renewal rate too high',
    'Cannot make current payment',
    'Service',
    'Moving',
    'Total RTO/Term too high',
    'SAC too high',
  ];

  useEffect(() => {
    if (containerData) {
      const getCurrentRole = containerData.GetRole();
      setCurrentRole(getCurrentRole);
    }
  }, [containerData]);

  useEffect(() => {
    const collectedRetentionOptions: any = [];
    if (offerResponse) {
      if (choosenOfferReason == 'Product' || choosenOfferReason == 'Service') {
        collectedRetentionOptions.push(
          {
            displayLabel: 'Repair or switch out product',
            offerValue: {
              redirectionPage: 'Agreement',
              functionalityInPage: 'SW',
            },
          },
          {
            displayLabel: 'Customer does not accept any offer',
          }
        );
      } else if (choosenOfferReason == 'No Longer Needs') {
        collectedRetentionOptions.push(
          {
            displayLabel: 'Explain lifetime reinstatement to the customer',
            offerValue: {
              redirectionPage: 'Agreement',
              functionalityInPage: 'R',
            },
          },
          {
            displayLabel: 'Customer does not accept any offer',
          }
        );
      } else if (choosenOfferReason == 'Moving') {
        collectedRetentionOptions.push(
          {
            displayLabel: 'Give customers their local RAC store info',
            offerValue: {
              redirectionPage: 'StoreManagement',
              functionalityInPage: 'MOVE',
            },
          },
          {
            displayLabel: 'Customer does not accept any offer',
          }
        );
      } else {
        // let alteredReasonDescription: any = '';
        // if (choosenOfferReason == 'Early Purchase Option (EPO) too high') {
        //   alteredReasonDescription = 'EPO too High';
        // } else if (choosenOfferReason == 'Same as Cash (SAC) too high') {
        //   alteredReasonDescription = 'SAC too High';
        // } else if (choosenOfferReason == 'Term Rent to own (TRTO) too high') {
        //   alteredReasonDescription = 'Total RTO/Term too high';
        // } else {
        //   alteredReasonDescription = choosenOfferReason;
        // }
        const filteredReason: any = offerResponse?.returnReasons.filter(
          (el: any) => el.reason == choosenOfferReason
        );
        offerResponse?.agreementOffers.forEach((offer: any) => {
          offer.updateDateTime = new Date(offer.updateDateTime);
        });
        const agreementData: any = offerResponse?.agreementOffers.sort(
          (a: any, b: any) => b.updateDateTime - a.updateDateTime
        );

        filteredReason.map((el: any) => {
          if (el.epoEligible == 'Yes') {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].epoOfferDescription,
              offerValue: {
                redirectionPage: 'Payment',
                functionalityInPage: 'EPO',
                currentEpo: agreementData[0].currentEpo,
                epoDiscount: agreementData[0].epoDiscount,
                newEpo: agreementData[0].newEpo,
              },
            });
          }
          if (el.rateReductionEligible == 'Yes') {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].rateOfferDescription,
              offerValue: {
                redirectionPage: 'Agreement',
                functionalityInPage: 'RR',
                currentRate: agreementData[0].currentRate,
                rateReduction: agreementData[0].rateReduction,
                newRate: agreementData[0].newRate,
              },
            });
          }
          if (el.termReductionEligible == 'Yes') {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].termOfferReduction,
              offerValue: {
                redirectionPage: 'Agreement',
                functionalityInPage: 'TR',
                currentRemainingTerm: agreementData[0].currentRemainingTerm,
                termReduction: agreementData[0].termReduction,
                newRemainingTerm: agreementData[0].newRemainingTerm,
              },
            });
          }
          if (el.extensionEligible == 'Yes') {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].extensionOfferDescription,
              offerValue: {
                redirectionPage: 'Payment',
                functionalityInPage: 'FREETIME',
                extension: agreementData[0].extension,
              },
            });
          }
        });
        if (collectedRetentionOptions.length) {
          collectedRetentionOptions.push({
            displayLabel: 'Customer does not accept any offer',
          });
        } else {
          collectedRetentionOptions.push({
            displayLabel: 'No retention offer option available',
          });
        }
      }
    } else {
      collectedRetentionOptions.push({
        displayLabel: 'No retention offer option available',
      });
    }
    setRetentionOptions(collectedRetentionOptions);
  }, [choosenOfferReason]);

  const SLACanceled = () => {
    setOpenSLA(false);
  };

  const SLACompleted = async () => {
    setOpenSLA(false);
    setOpenOfferLoading(true);
    try {
      const retentionOffersResult = await getRetentionOffers({
        agreementNumbers: [String(choosenAgreementNumber)],
      });
      const collectedLabels: any = [];
      const constantLabels: any = [
        'Product',
        'No Longer Needs',
        'Service',
        'Moving',
      ];
      retentionOffersResult.returnReasons.map((el: any) => {
        collectedLabels.push(el.reason);
      });
      setAvailableOffers([...constantLabels, ...collectedLabels]);
      if (retentionOffersResult) {
        setOfferResponse(retentionOffersResult);
      }
      setOpenRetention(true);
    } catch {
      setOfferResponse(undefined);
      setAvailableOffers(availableOffers);
      setOpenRetention(true);
    }
    setOpenOfferLoading(false);
  };

  const offerLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Retention Offers
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  const acceptReinstate = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split('T')[0];

    const agreementActivityPayloadObj = {
      agreementActivities: [
        {
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: 'No Longer Needs Selected',
          agreementActivityType: 'NLNS',
        },
      ],
    };

    try {
      await postAgreementActivity(agreementActivityPayloadObj);
      await logWorkedHistory({
        storeNumber: sessionStorage.getItem('storeNumber') || '',
        customerId: Number(customerId),
        coWorkerId: user?.employeeId || ' ',
        acctActivityRefCode: 'CUSTOFFER',
        activityDate: new Date().toISOString(),
        notes:
          choosenRetentionOptions.displayLabel +
          ` agreement number: ${choosenAgreementNumber}`,
      });
    } catch {
      // Exception Handle
    }
    history.push({
      pathname: `${AppRoute.CustomerAggrement}/${customerId}/${choosenAgreementID}`,
      search: `?source=AMRETENTION&type=R`,
      state: {
        retentionData: offerResponse,
      },
    });
    setOpenActivityLoading(false);
  };

  const activityLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Saving Information
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  const buildReinstateAlert = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.bold}`}>
            At any time you can contact your store to schedule a return of your
            product or return merchandise in person and pause your payments.
            When you are ready, you can come back, reinstate your agreement to
            get the same or comparable item, and pick up your payments right
            where you left off.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              acceptReinstate();
            }}
          >
            Return
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              setOpenRetention(true);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const closeRetentionPopup = async () => {
    setOpenRetention(false);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setChoosenOfferReason(undefined);

    const today = new Date();
    const date = today.toISOString().split('T')[0];
    const payloadAgreementActivities: any = [];
    if (choosenOfferReason == 'Total RTO/Term too high') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `TRTO high Canceled: sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRTRT',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRTRT',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRTRTO',
        });
      }
    } else if (choosenOfferReason == 'SAC too High') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `SAC high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRSACT',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRSACT',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRSACTH',
        });
      }
    } else if (choosenOfferReason == 'EPO too High') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `EPO high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCREPOT',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCREPOT',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCREPOTH',
        });
      }
    } else if (choosenOfferReason == 'Renewal rate too high') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Rate high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRRTH',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRRTH',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Not Sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRRTH',
        });
      }
    } else if (choosenOfferReason == 'Cannot make current payment') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `make paymnt Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRCMCP',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRCMCP',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCCRCMCP',
        });
      }
    } else if (choosenOfferReason == 'Service') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Service: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRSERV',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRSERV',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRSERV',
        });
      }
    } else if (choosenOfferReason == 'Product') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Product: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRPROD',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRPROD',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRPROD',
        });
      }
    } else if (choosenOfferReason == 'Moving') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Moving: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRMOVI',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRMOVI',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRMOVI',
        });
      }
    } else if (choosenOfferReason == 'No Longer Needs') {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== 'Customer does not accept any offer' &&
          el.displayLabel !== 'No retention offer option available'
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: 'CANSCRNLN',
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        'Customer does not accept any offer'
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: 'CANSCRNLN',
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: 'CANCRNLN',
        });
      }
    }
    if (payloadAgreementActivities.length) {
      try {
        const agreementActivityPayloadObj = {
          agreementActivities: payloadAgreementActivities,
        };
        await postAgreementActivity(agreementActivityPayloadObj);
        await logWorkedHistory({
          storeNumber: sessionStorage.getItem('storeNumber') || '',
          customerId: Number(customerId),
          coWorkerId: user?.employeeId || ' ',
          acctActivityRefCode: 'CUSTOFFER',
          activityDate: new Date().toISOString(),
          notes:
            choosenRetentionOptions.displayLabel +
            ` agreement number: ${choosenAgreementNumber}`,
        });
      } catch {
        // No Catch
      }
    }
  };

  // const onCheckBoxClicked = (index: any, value: any) => {
  //   const copyCheckBoxIndex = [...[], ...choosenOptionIndex];
  //   const copyChoosenRetentionOptions = [...[], ...choosenRetentionOptions];
  //   if (!copyCheckBoxIndex.includes(index)) {
  //     copyCheckBoxIndex.push(index);
  //     copyChoosenRetentionOptions.push(value);
  //   } else {
  //     copyCheckBoxIndex.splice(copyCheckBoxIndex.indexOf(index), 1);
  //     copyChoosenRetentionOptions.splice(
  //       copyChoosenRetentionOptions.indexOf(index),
  //       1
  //     );
  //   }
  //   setChoosenRetentionOptions(copyChoosenRetentionOptions);
  //   setChoosenIndex(copyCheckBoxIndex.sort());
  // };

  const recordRedirection = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split('T')[0];
    const agreementActivityPayload: any = [];
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Payment' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'EPO'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agreement EPO Selected',
        agreementActivityType: 'AEPOS',
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Payment' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'FREETIME'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agreement Extension Selected',
        agreementActivityType: 'AEXTNS',
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'RR'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agreement Rate Adjustment Selected',
        agreementActivityType: 'ARAS',
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'TR'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agreement Term Adjustment Selected',
        agreementActivityType: 'ATAS',
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'SW'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agr Retention Switchout Selected',
        agreementActivityType: 'AGMRETSWS',
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'S'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agr Retention Service Selected',
        agreementActivityType: 'AGMRETSS',
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        'StoreManagement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'MOVE'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Moving Selected',
        agreementActivityType: 'MS',
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      'Customer does not accept any offer'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agr Retention No Offer Selected',
        agreementActivityType: 'AGMRETNONE',
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      'No retention offer option available'
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: 'Agr Retention No Offer Available',
        agreementActivityType: 'RETNOAVAIL',
      });
    }
    const agreementActivityPayloadObj = {
      agreementActivities: agreementActivityPayload,
    };
    try {
      await postAgreementActivity(agreementActivityPayloadObj);
      await logWorkedHistory({
        storeNumber: sessionStorage.getItem('storeNumber') || '',
        customerId: Number(customerId),
        coWorkerId: user?.employeeId || ' ',
        acctActivityRefCode: 'CUSTOFFER',
        activityDate: new Date().toISOString(),
        notes:
          choosenRetentionOptions.displayLabel +
          ` agreement number: ${choosenAgreementNumber}`,
      });
    } catch {
      // Exception Handle
    }

    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Payment' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'EPO'
    ) {
      const epoDiscountAmount = choosenRetentionOptions?.offerValue?.epoDiscount
        .trim()
        .replace(/\$/g, '');
      history.push({
        pathname: `${AppRoute.TakePayment}/${customerId}/0`,
        search: `?CRTYPE=EPO`,
        state: {
          retentionData: {
            newEpo: choosenRetentionOptions?.offerValue?.newEpo,
            epoDiscount: epoDiscountAmount,
            currentEpo: choosenRetentionOptions?.offerValue?.currentEpo,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
            retentionEPOMessage: choosenRetentionOptions.displayLabel,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Payment' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'FREETIME'
    ) {
      history.push({
        pathname: `${AppRoute.TakePayment}/${customerId}/0`,
        search: `?CRTYPE=FREETIME`,
        state: {
          retentionData: {
            extentionDays: choosenRetentionOptions?.offerValue?.extension,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'RR'
    ) {
      history.push({
        pathname: `${AppRoute.CustomerAggrement}/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=RR`,
        state: {
          retentionData: {
            retentionData: offerResponse,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'TR'
    ) {
      history.push({
        pathname: `${AppRoute.CustomerAggrement}/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=TR`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'SW'
    ) {
      history.push({
        pathname: `${AppRoute.CustomerAggrement}/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=SW`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'R'
    ) {
      history.push({
        pathname: `${AppRoute.CustomerAggrement}/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=R`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == 'Agreement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'S'
    ) {
      history.push({
        pathname: `${AppRoute.CustomerAggrement}/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=S`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        'StoreManagement' &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == 'MOVE'
    ) {
      history.push({
        pathname: `${AppRoute.StoreManagement}`,
      });
    }
    setOpenActivityLoading(false);
  };

  const renderRetentionModal = () => {
    return (
      <>
        <Grid container className={classes.mt2}>
          <Typography
            variant="h6"
            className={`${classes.title} ${classes.mb1}`}
          >
            Please select why the customer wishes to return to see Customer
            Retention Offer
          </Typography>
          <Grid container spacing={2}>
            {availableRetentionOffers?.map((el: any, index: any) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={`${classes.mb2}`}
                  key={index}
                >
                  <Card
                    className={
                      el == choosenOfferReason
                        ? `${classes.cardTile} ${classes.payScheduleHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                        : `${classes.cardTile} ${classes.payScheduleNotHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                    }
                    onClick={() => {
                      setChoosenOfferReason(el);
                      setChoosenRetentionOptions(undefined);
                      setChoosenIndex(undefined);
                    }}
                  >
                    <CardContent>
                      <Grid container className={classes.justifyLabel}>
                        <Typography
                          className={
                            el == choosenOfferReason
                              ? classes.paySchedulePeriodHighlight
                              : classes.paySchedulePeriodNotHighlight
                          }
                          variant="body1"
                        >
                          {el}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {choosenOfferReason ? (
            <Grid container className={`${classes.mb2}`}>
              <Card
                className={`${classes.card} ${classes.w100} ${classes.alertwidget} ${classes.mb1p}`}
              >
                <Grid
                  item
                  className={`${classes.w100} ${classes.px0} ${classes.floatLeft}`}
                >
                  <Grid container className={`${classes.px2} ${classes.h65}`}>
                    <Grid
                      className={`${classes.w100} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                    >
                      <Grid
                        item
                        className={`${classes.raccollg6}  ${classes.floatLeft}`}
                      >
                        <Typography
                          component="span"
                          className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                        >
                          Retention Option
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={`${classes.px2}`}>
                    {retentionOptions?.map((el: any, index: any) => {
                      return (
                        <Grid
                          className={`${classes.w100} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                          key={index}
                        >
                          <RACCheckBox
                            size="medium"
                            key={index}
                            onChange={() => {
                              if (choosenOptionIndex != index) {
                                setChoosenIndex(index);
                                setChoosenRetentionOptions(el);
                              } else {
                                setChoosenIndex(undefined);
                                setChoosenRetentionOptions(undefined);
                              }
                              //onCheckBoxClicked(index, el);
                            }}
                            checked={choosenOptionIndex == index ? true : false}
                            label={el.displayLabel}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.w100} ${classes.px20} ${classes.floatRight} `}
                >
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="contained"
                    disabled={choosenRetentionOptions ? false : true}
                    onClick={() => {
                      if (
                        choosenRetentionOptions?.displayLabel ==
                        'Explain lifetime reinstatement to the customer'
                      ) {
                        setOpenRetention(false);
                        setOpenReinstateAlert(true);
                      } else {
                        recordRedirection();
                        setOpenRetention(false);
                      }
                    }}
                  >
                    Record
                  </RACButton>
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="outlined"
                    onClick={() => closeRetentionPopup()}
                  >
                    Cancel
                  </RACButton>
                </Grid>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  const renderTableHead = () => (
    <>
      <RACTableCell>
        <Typography variant="h5">Agreement</Typography>
      </RACTableCell>
      <RACTableCell>
        <Typography variant="h5">Due Date</Typography>
      </RACTableCell>
      <RACTableCell>
        <Typography variant="h5">Type</Typography>
      </RACTableCell>
      <RACTableCell>
        <Typography variant="h5">Current</Typography>
      </RACTableCell>
      <RACTableCell color="green">
        <Typography className={classes.greenColumn} variant="h5">
          On Time
        </Typography>
      </RACTableCell>
      <RACTableCell>
        <Typography variant="h5">1-6 Days Late</Typography>
      </RACTableCell>
      <RACTableCell>
        <Typography variant="h5">7-14 Days Late</Typography>
      </RACTableCell>
      <RACTableCell>
        <Typography variant="h5">15+ Days Late</Typography>
      </RACTableCell>
      {isRetentionEnabled ? (
        <RACTableCell>
          <Typography variant="h5">Action</Typography>
        </RACTableCell>
      ) : null}
    </>
  );

  const daysLateHistoryCellGenerator = (
    daysLateHistory: AgreementInfoDaysLate[]
  ) => {
    const populatedDaysLateHistory = populateDaysLateHistory(daysLateHistory);

    return populatedDaysLateHistory.map(
      ({ count, dayslate }: AgreementInfoDaysLate) => (
        <RACTableCell key={dayslate}>
          <Typography variant="body2" className={classes.tableContentColor}>
            {count}
          </Typography>
        </RACTableCell>
      )
    );
  };
  const renderTableContent = () => (
    <>
      {stores?.map((storeObj) => {
        if (!storeObj?.agreements?.length) return null;

        return storeObj?.agreements?.map(
          (
            {
              agreementNumber,
              agreementId,
              nextDueDate,
              currentDaysLate,
              daysLateHistory,
              contractType,
            },
            index
          ) => {
            const dueDateString = `${formatDateString(nextDueDate)}`;
            const showAgreementNumLink = contractType === 'CLUB' ? false : true;
            return (
              <>
                {index === 0 && (
                  <RACTableRow
                    key={`${index}-${storeObj.storeNumber}`}
                    backgroundColor="white"
                  >
                    <RACTableCell className={classes.storeRowContentCell}>
                      <div className={classes.storeRowContent}>
                        <Typography variant="h4">{`${storeObj.storeNumber} - ${storeObj.city}, ${storeObj.state}`}</Typography>
                      </div>
                    </RACTableCell>
                    <RACTableCell>
                      <Typography
                        noWrap
                        display="inline"
                        variant="body2"
                        className={classes.notVisible}
                      >
                        {/* Using typography to ensure that all rows have the same
                        height(this is not visible in the table)*/}
                        0
                      </Typography>
                    </RACTableCell>
                  </RACTableRow>
                )}
                <RACTableRow key={index} backgroundColor="white">
                  <RACTableCell classes={{ root: classes.number }}>
                    {showAgreementNumLink ? (
                      <Link
                        style={{ color: 'inherit', textDecoration: 'unset' }}
                        to={{
                          pathname: `${AppRoute.CustomerAggrement}/${customerId}/${agreementId}`,
                          search: `?origin=am-customer`,
                        }}
                        className={'disi'}
                      >
                        {agreementNumber}
                      </Link>
                    ) : (
                      <Typography
                        display="inline"
                        variant="body2"
                        className={classes.tableContentColor}
                      >
                        {agreementNumber}
                      </Typography>
                    )}
                  </RACTableCell>
                  <RACTableCell>
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.tableContentColor}
                    >
                      {dueDateString}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.tableContentColor}
                    >
                      {contractType}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.tableContentColor}
                    >
                      {currentDaysLate}
                    </Typography>
                  </RACTableCell>
                  {daysLateHistoryCellGenerator(daysLateHistory)}
                  {isRetentionEnabled ? (
                    <RACTableCell>
                      {contractType !== 'CLUB' &&
                      storeObj?.storeNumber ==
                        String(window.sessionStorage.getItem('storeNumber')) ? (
                        <a
                          onClick={() => {
                            setOpenSLA(true);
                            setChoosenAgreementNumber(agreementNumber);
                            setChoosenAgreementID(agreementId);
                          }}
                          className={classes.hyperLink}
                        >
                          Retention
                        </a>
                      ) : (
                        '-'
                      )}
                    </RACTableCell>
                  ) : null}
                </RACTableRow>
              </>
            );
          }
        );
      })}
    </>
  );
  const classes = useClasses();
  const { fetchAgreementInfo } = useAgreementInfoActions();

  const initialLoad = () => {
    fetchAgreementInfo();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialLoad, []);
  return (
    <>
      <InfiniteScroll
        next={fetchAgreementInfo}
        dataLength={numberOfLoadedAgreements}
        hasMore={numberOfLoadedAgreements < total}
        height={330}
        loader={<Loader />}
      >
        <ApiStateWrapper
          loading={loading}
          hasApiError={hasApiError}
          response={stores}
          successContent={
            <RACTable
              bodyClasses={{ root: classes.tableBody }}
              headClasses={{ root: classes.tableHead }}
              classes={{ root: classes.tableRoot }}
              renderTableHead={renderTableHead}
              renderTableContent={renderTableContent}
            />
          }
          classes={{
            loader: classes.contentHeight,
            apiError: classes.contentHeight,
            noItems: classes.contentHeight,
          }}
        />
      </InfiniteScroll>
      <RACModalCard
        isOpen={openOfferLoading}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={offerLoadingRenderer()}
      />
      <RACModalCard
        isOpen={openReinstateAlert}
        maxWidth="sm"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildReinstateAlert()}
      />
      <RACModalCard
        isOpen={openActivityLoading}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={activityLoadingRenderer()}
      />
      <RACModalCard
        isOpen={openRetention}
        maxWidth="xl"
        title="Customer Retention"
        borderRadius={'25px !important'}
        closeIcon={true}
        onClose={() => closeRetentionPopup()}
        /* eslint-disable react/no-children-prop */
        children={renderRetentionModal()}
      />
      {openSLA === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACanceled}
          setTwoFactorCompleted={SLACompleted}
          CurrentRole={currentRole}
          moduleName="CustomerRetention"
        />
      ) : null}
    </>
  );
};
